import axios from 'axios'
import useSWRInfinite from 'swr/infinite'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { Conversation } from '@/types/chatbot'

const useConversations = (limit: number, email?: string) => {
  const { data, error, isLoading, mutate, size, setSize } = useSWRInfinite(
    (page) => [email, page],
    (args) =>
      !email
        ? null
        : axios(`${API_URL}/conversations`, {
            method: 'get',
            withCredentials: true,
            params: { email: args[0], page: Number(args[1]), limit },
            ...configHeader,
          }),
    { revalidateOnFocus: false }
  )

  let conversations: Conversation[] = []
  if (data) {
    conversations = data.reduce((acc: Conversation[], pageData) => {
      if (pageData) {
        acc.push(...pageData.data.conversations)
      }
      return acc
    }, [])
  }

  const noMoreData =
    data && data[data.length - 1]?.data.conversations.length < limit

  return {
    conversations,
    isLoading,
    error,
    mutate,
    size,
    setSize,
    noMoreData,
  }
}

export default useConversations
