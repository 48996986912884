import axios from 'axios'
import useSWR from 'swr'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { GrantApplication } from '@/types/grants'

const useGrantApplication = (
  conversationId?: string,
  existingConversation?: boolean
) => {
  const config = {
    method: 'get',
    withCredentials: true,
    ...configHeader,
  }

  const { data, error, isLoading, mutate } = useSWR(
    conversationId && existingConversation
      ? ['grantApplication', conversationId]
      : null,
    async () => axios(`${API_URL}/grant-thread/${conversationId}`, config),
    { revalidateOnFocus: false }
  )

  return {
    grantApplication: data?.data.data as GrantApplication,
    isLoading,
    error,
    mutate,
  }
}

export default useGrantApplication
