'use client'

import { Switch } from 'antd'
import { useTheme } from 'next-themes'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { cn } from '@/utils/clsx'

import { Theme } from '@/types/theme'

interface ThemeSwitchProps {
  className?: string
}

const ThemeSwitch: React.FC<ThemeSwitchProps> = ({ className }) => {
  const [mounted, setMounted] = useState(false)
  const { setTheme, resolvedTheme } = useTheme()
  const { t } = useTranslation()

  useEffect(() => setMounted(true), [])

  const switchTheme = () => {
    setTheme(resolvedTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK)
  }

  return (
    <>
      {mounted && (
        <div className={cn('flex gap-2 items-center', className)}>
          <p>{t('dark-mode')}</p>
          <Switch
            checked={resolvedTheme === Theme.DARK}
            onClick={switchTheme}
          />
        </div>
      )}
    </>
  )
}

export default ThemeSwitch
