'use client'

import { getCookie } from 'cookies-next'
import { usePathname, useRouter } from 'next/navigation'
import { useContext, useEffect, useState } from 'react'

import useAuth from '@/hooks/useAuth'
import useConversation from '@/hooks/useConversation'
import useConversations from '@/hooks/useConversations'
import useDbSettings from '@/hooks/useDbSettings'
import useGrantApplication from '@/hooks/useGrantApplication'

import ChatLayout from '@/components/Admin/Conversations/ChatLayout'
import Matcher from '@/components/Admin/Matcher'
import Chatbot, { IndexPageContext } from '@/components/Chatbot'
import IndexHeader from '@/components/IndexHeader'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { switchLanguage } from '@/i18n'
import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

const Home = () => {
  const { error, isLoading, user } = useAuth()
  const router = useRouter()
  const pathname = usePathname()
  const [matcherOpen, setMatcherOpen] = useState(true)
  const { settings } = useDbSettings()
  const { mutate, conversations, setSize, noMoreData } = useConversations(
    30,
    user?.email
  )
  const { selectedConversation, setSelectedConversation } =
    useContext(DrawerContext)
  const { conversation } = useConversation(selectedConversation)
  const { grantApplication } = useGrantApplication(
    selectedConversation,
    !!conversation && conversation.length > 0
  )

  useEffect(() => {
    if (grantApplication?.currentStep === 8) {
      setMatcherOpen && setMatcherOpen(false)
    } else {
      setMatcherOpen && setMatcherOpen(true)
    }
  }, [grantApplication])

  useEffect(() => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }, [selectedConversation])

  useEffect(() => {
    if (error) {
      router.replace(
        `/login${pathname ? '?redirect=' + encodeURIComponent(pathname) : ''}`
      )
    }
    if (
      settings?.requireSubscription &&
      user &&
      !user.subscribed &&
      user.role !== 'ADMIN'
    ) {
      router.replace('/landing')
    }
  }, [error, user, settings])

  // Set language from cookie
  const lang = getCookie('locale')
  if (lang) {
    setTimeout(() => switchLanguage(lang))
  }

  return (
    <>
      {isLoading || error ? (
        <></>
      ) : (
        <ChatLayout
          conversations={conversations}
          mutateConversations={mutate}
          setConversationsSize={setSize}
          noMoreData={noMoreData}
        >
          {matcherOpen ? (
            <IndexPageContext.Provider value={true}>
              <main
                className={cn(
                  'relative h-screen flex flex-col bg-background dark:bg-dark-background shadow-md rounded'
                )}
              >
                <div className='w-full'>
                  <IndexHeader />
                </div>
                <Matcher
                  setMatcherOpen={setMatcherOpen}
                  grantApplication={grantApplication}
                  conversationId={selectedConversation}
                  conversation={conversation}
                  mutateConversations={mutate}
                />
              </main>
            </IndexPageContext.Provider>
          ) : (
            <Chatbot indexPage />
          )}
        </ChatLayout>
      )}
    </>
  )
}

export default Home
